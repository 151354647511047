import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Chip, Paper, Typography } from '@mui/material';
import { Pill } from 'components/Pill';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { HomestayProvider } from 'types/homestay-provider.enum';
import { Curriculum, ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useAsync } from 'hooks/use-async';
import { useEffect, useMemo, useState } from 'react';
import { Country } from 'types/country.interface';

export const SidebarInfoPanel = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const coedStatusText = profile.coedStatus
    ? {
        [CoedStatuses.Male]: 'Boys only',
        [CoedStatuses.Female]: 'Girls only',
        [CoedStatuses.CoEd]: 'Coeducational',
      }[profile.coedStatus]
    : null;
  const boardingStatusText = {
    day: 'Day school only',
    boarding: 'Boarding school only',
    dayAndBoarding: 'Day school with Boarding facilities',
  }[profile.dayOrBoarding];

  const religiousAffiliationText = profile.religiousAffiliation ? `${profile.religiousAffiliation} Affiliation` : 'No Religious Affiliation';

  const isInternationalCurriculum = (c: Curriculum) => ['International Baccalaureate', 'Cambridge International'].includes(c.name);
  const displayCurriculums = profile.curriculums.filter(isInternationalCurriculum).map((c) => c.name);

  // Highlight special homestay providers as pills instead of text.
  const homestayPillProviders = [HomestayProvider.Educatius, HomestayProvider.AustralianHomestayNetwork];
  const showHomestayProviderAsPill = homestayPillProviders.includes(profile.homestayAccomodation!);
  const homestayProviderEl = showHomestayProviderAsPill ? (
    <Pill label={profile.homestayAccomodation} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
  ) : (
    profile.homestayAccomodation
  );

  const [countries] = useAsync<Country[]>('/countries');
  const flagSrc = useMemo(() => {
    const country = countries?.find((country) => country.name === profile.country);
    return country ? `/flag-icons/${country.iso_3166_3}.svg` : '';
  }, [countries, profile]);

  const [isFlagError, setIsFlagError] = useState<boolean>(false);

  useEffect(() => {
    const flagImage = new Image();
    flagImage.onerror = () => {
      setIsFlagError(true);
    };
    if (flagSrc) flagImage.src = flagSrc;
  }, [flagSrc]);

  return (
    <Paper sx={{ padding: '8px' }}>
      <Box m={1}>
        <Typography>
          {profile.city}
          {profile.state !== profile.city ? `, ${profile.state}` : ''}
        </Typography>
        <Box>
          <Chip
            label={profile?.country || ''}
            icon={isFlagError ? <></> : <img src={flagSrc} alt={`${profile?.country} flag`.trim()} width="16px" height="16px" />}
            size="small"
            sx={{ m: 1, backgroundColor: 'background.paper' }}
          />
        </Box>
      </Box>
      {displayCurriculums.length > 0 && (
        <>
          <Typography m={1}>International curriculum:</Typography>
          {displayCurriculums.map((name) => (
            <Pill key={name} label={name} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
          ))}
        </>
      )}
      <Typography m={1}>{profile.isPrivateSchool ? 'Private school' : 'Public school'}</Typography>
      {boardingStatusText && <Typography m={1}>{boardingStatusText}</Typography>}
      {profile.homestayAccomodation && <Typography m={1}>Homestay managed by {homestayProviderEl}</Typography>}
      {coedStatusText && (
        <Typography m={1} sx={{ textTransform: 'capitalize' }}>
          {coedStatusText}
        </Typography>
      )}
      {profile.totalStudentCount && <Typography m={1}>{profile.totalStudentCount} total students</Typography>}
      {profile.averageClassSize && <Typography m={1}>{profile.averageClassSize} average class size</Typography>}
      {profile.internationalStudentPercentage && <Typography m={1}>{profile.internationalStudentPercentage}% international students</Typography>}
      <Typography m={1}>{religiousAffiliationText}</Typography>
    </Paper>
  );
};
