import { Box, Paper, Typography, useTheme } from '@mui/material';
import { HighlightAvatar } from 'components/HighlightAvatar';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';

export const HighlightsSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ mb: '10px' }}>
        <Box sx={{ p: '20px' }}>
          <Typography mb={3} variant="h5" fontSize="24px" fontWeight={700} color={color}>
            Highlights
          </Typography>
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" textAlign="center">
            <Box display="flex" flexDirection="column" alignItems="center" gridColumn={1}>
              <HighlightAvatar icon={profile.highlightIcon1} iconScale={5} width={200} height={200} color={color} />
              <Typography mt={1}>{profile.highlightText1}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" gridColumn={2}>
              <HighlightAvatar icon={profile.highlightIcon2} iconScale={5} width={200} height={200} color={color} />
              <Typography mt={1}>{profile.highlightText2}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" gridColumn={3}>
              <HighlightAvatar icon={profile.highlightIcon3} iconScale={5} width={200} height={200} color={color} />
              <Typography mt={1}>{profile.highlightText3}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </section>
  );
};
