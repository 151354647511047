import { useAuth0 } from '@auth0/auth0-react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { usePageTitle } from 'app/PageTitleContext';
import { Footer } from 'components/Footer';
import { Loader } from 'components/Loader';
import { useIsIframed } from 'hooks/use-is-iframed';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { FileRoles } from 'types/file-roles.enum';
import { AuthUser } from 'types/user.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { AboutSection } from './AboutSection';
import { AcademicSection } from './AcademicSection';
import { DocumentsSection } from './DocumentsSection';
import { FeaturesSection } from './FeaturesSection';
import { HighlightsSection } from './HighlightsSection';
import { GallerySection } from './GallerySection';
import { LocationSection } from './LocationSection';
import { SchoolProfileHeader } from './SchoolProfileHeader';
import { SidebarInfoPanel } from './SidebarInfoPanel';
import { StudyProgramsSection } from './StudyProgramsSection';
import { TestimonialsSection } from './TestimonialsSection';
import { EducatiusPricingMessage } from './EducatiusPricingMessage';

type ViewProps = {
  ignorePublishedState?: boolean;
};

export const View = ({ ignorePublishedState }: ViewProps) => {
  const navigate = useNavigate();
  const isIframed = useIsIframed();
  const isPdfFormat = useIsPdfFormat();
  const { user } = useAuth0<AuthUser>();

  const {
    state: { profile, status: profileStatus, isSharedView },
  } = useProfile();

  const theme = useTheme();
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    if (profile) {
      setPageTitle(profile.name);
      ReactGA.event({ category: 'School Page View', label: profile.name, action: 'Viewed' });
    }
  }, [profile, setPageTitle]);

  if (profileStatus !== 'success') {
    return <Loader />;
  }

  if (!profile || (!profile.isPublished && !ignorePublishedState)) {
    return <Typography variant="h1">Coming soon...</Typography>;
  }

  const files = profile.files || [];

  const hasVideos = profile.videoUrls?.length > 0;
  const hasPhotos = files.some((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_PHOTOS);
  const shouldRenderGallery = hasPhotos || hasVideos;

  const documentRoles = [FileRoles.FILE_ROLE_PROSPECTUS, FileRoles.FILE_ROLE_CONTRACTS_AGREEMENTS, FileRoles.FILE_ROLE_MARKETING_MATERIALS];
  const hasDocuments = files.some((file) => documentRoles.includes(file.role));
  const shouldRenderDocuments = !isPdfFormat && hasDocuments;

  const shouldRenderStudyPrograms: boolean = profile.studyPrograms?.length > 0;
  const shouldRenderLocation = !isPdfFormat && Boolean(profile.googlePlaceId);

  const isEducatiusProfile = Boolean(profile?.isExternallyManaged) && Boolean(profile?.educatiusSchoolId);
  // Show coming soon message to any school that is UK Ireland or EU from Educatius and only for Educatius agents
  // Using logic that all these schools have no pricing and are an Educatius schools so don't need to check country
  const shouldRenderEducatiusPricingMessage = !shouldRenderStudyPrograms && isEducatiusProfile && Boolean(user?.userData.contractedWithEducatius);

  return (
    <Stack direction="column" bgcolor="white">
      <Container sx={{ p: 0 }}>
        <SchoolProfileHeader />
        <Stack direction="row">
          <Box width="290px" mr="10px">
            {!isSharedView && isIframed && (
              <Button
                sx={{ marginBottom: '10px', padding: '8px', backgroundColor: theme.palette.background.paper }}
                variant="outlined"
                onClick={() => navigate('/marketing')}
                startIcon={<KeyboardArrowLeftIcon />}
                fullWidth
              >
                Marketplace
              </Button>
            )}
            <SidebarInfoPanel />
            {isIframed && <img style={{ width: '100%', paddingTop: '20px' }} src="/powered-by-enroller.png" alt="Powered by Enroller" />}
          </Box>

          <Box component="main" width="100%" sx={{ overflowX: 'auto', overflowY: isPdfFormat ? 'initial' : 'none' }}>
            <AboutSection />

            {profile.showHighlightsSection && <HighlightsSection />}

            {shouldRenderStudyPrograms && <StudyProgramsSection />}

            {/* TODO: Move this and its render condition logic into the StudyProgramsSection and split up that component more too */}
            {/* There shouldn't be two top level sections with the same section title */}
            {shouldRenderEducatiusPricingMessage && <EducatiusPricingMessage />}

            {profile.showAcademicInfoSection && <AcademicSection />}

            {profile.showFeaturesSection && <FeaturesSection />}

            {shouldRenderLocation && <LocationSection />}

            {profile.showTestimonialsSection && <TestimonialsSection />}

            {shouldRenderGallery && <GallerySection />}

            {shouldRenderDocuments && <DocumentsSection />}
          </Box>
        </Stack>
      </Container>
      <Box flex={1} />
      {!isIframed && <Footer />}
    </Stack>
  );
};
