import { Box, Paper, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';

export const TestimonialsSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ p: 20, mb: '20px' }}>
        <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor}>
          Testimonials
        </Typography>
        <br />
        {profile.testimonials.map((testimonial) => (
          <Box key={testimonial.name} display="grid" gridTemplateColumns="120px 1fr">
            <img src="/testimonial-icon-grey.png" alt="Quotation marks" style={{ width: '100px', gridColumn: '1' }} />
            <Box gridColumn="2">
              <Typography variant="h6" fontWeight="500">
                {testimonial.name}
              </Typography>
              <Typography m={0}>{testimonial.type}</Typography>
              <Typography>{testimonial.quote}</Typography>
            </Box>
            <br />
          </Box>
        ))}
      </Paper>
    </section>
  );
};
